.border-none[data-v-6e72cdde]:before {
  display: none;
}
.border-none[data-v-6e72cdde]:after {
  display: none;
}
.flex-fix[data-v-6e72cdde] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-6e72cdde]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-6e72cdde] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-6e72cdde] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-6e72cdde] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-6e72cdde] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-6e72cdde] {
  *zoom: 1;
}
.clear-fix[data-v-6e72cdde]:before,
.clear-fix[data-v-6e72cdde]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.transitPage[data-v-6e72cdde] {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #fff;
  font-size: 0.28rem;
  color: #666;
  letter-spacing: 0.02rem;
}
.transitPage .content[data-v-6e72cdde] {
  margin: 0 0.7rem;
}
.transitPage .content .cont-img[data-v-6e72cdde] {
  text-align: center;
}
.transitPage .content .cont-img img[data-v-6e72cdde] {
  width: 2rem;
}
.transitPage .content .continue[data-v-6e72cdde] {
  padding: 0.9rem 0 0.6rem 0;
}
.transitPage .content .confirm .tip[data-v-6e72cdde] {
  color: var(--main-color);
}
.transitPage .content .confirm .tip-cont[data-v-6e72cdde] {
  line-height: 0.5rem;
}
.transitPage .content .btn[data-v-6e72cdde] {
  text-align: center;
}
.transitPage .content .btn button[data-v-6e72cdde] {
  background: var(--main-color);
  color: #fff;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  width: 2rem;
  height: 0.68rem;
  border-radius: 0.68rem;
  text-align: center;
  line-height: 0.68rem;
  font-size: 0.28rem;
  border: none;
}
