.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.transitPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-size: 0.28rem;
  color: #666;
  letter-spacing: 0.02rem;
}
.transitPage .content {
  margin: 0 0.7rem;
}
.transitPage .content .cont-img {
  text-align: center;
}
.transitPage .content .cont-img img {
  width: 2rem;
}
.transitPage .content .continue {
  padding: 0.9rem 0 0.6rem 0;
}
.transitPage .content .confirm .tip {
  color: var(--main-color);
}
.transitPage .content .confirm .tip-cont {
  line-height: 0.5rem;
}
.transitPage .content .btn {
  text-align: center;
}
.transitPage .content .btn button {
  background: var(--main-color);
  color: #fff;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  width: 2rem;
  height: 0.68rem;
  border-radius: 0.68rem;
  text-align: center;
  line-height: 0.68rem;
  font-size: 0.28rem;
  border: none;
}
